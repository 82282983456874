<template>
  <Field v-slot="{ field }" type="text">
    <input
      @keypress="checkInput"
      v-bind="field"
      :class="$attrs.class"
      :placeholder="$attrs.placeholder"
      :maxlength="$attrs.maxlength"
    />
  </Field>
</template>

<script>
import { Field } from "vee-validate";

export default {
  name: "NumberInput",
  components: {
    Field,
  },

  props: {
    min: {
      type: Number,
      default: null,
    },
    max: {
      type: Number,
      default: null,
    },
  },

  data: () => ({
    numberRules: [],
  }),

  methods: {
    checkInput(event) {
      if (event.keyCode < 48 || event.keyCode > 57) {
        event.preventDefault();
        event.stopPropagation();
      }
    },
  },
};
</script>
