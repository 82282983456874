<template>
  <Form
    class="gs-form"
    @submit="requestGutschein"
    :validation-schema="schema"
    v-slot="{ meta }"
  >
    <div class="flex justify-center text-left p-6">
      <div
        class="flex flex-col md:flex-row md:max-w-xl rounded-lg bg-white shadow-lg"
      >
        <div class="p-6 flex flex-col justify-start">
          <div class="m-4 form-floating">
            <label for="mnr">Mitgliedsnummer</label>
            <NumberInput
              name="mnr"
              v-model="mnr"
              placeholder="606012345"
              :maxlength="mnrInputMax"
              :validateOnInput="true"
              @blur="checkFormat"
              class="form-control text-xl block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            />
            <ErrorMessage name="mnr" class="text-red-600" />
          </div>

          <div class="m-4 form-floating">
            <label for="mnr">ÖSV PIN</label>
            <NumberInput
              name="pin"
              v-model="pin"
              class="form-control text-xl block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              placeholder="1234"
              :maxlength="pinMaxLength"
              :validateOnInput="true"
            />
            <ErrorMessage name="pin" class="text-red-600" />
          </div>

          <div class="m-4 form-floating">
            <label for="mnr">E-Mail Adresse</label>
            <Field
              name="email"
              v-model="email"
              type="email"
              class="form-control text-xl block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              placeholder="max@mustermann.at"
              :validateOnInput="true"
            />
            <ErrorMessage name="email" class="text-red-600" />
          </div>
          <div class="m-4 form-floating">
            <Field
              name="agbs"
              v-model="agbs"
              type="checkbox"
              id="agbs"
              value="true"
              :validateOnInput="true"
              class="form-check-input h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
            />

            <label
              class="form-check-label inline-block text-gray-800"
              for="agbs"
            >
              Ich habe die
              <a
                href=" https://www.oesv.at/datenschutz"
                target="_blank"
                class="agb-link"
                >Datenschutzerklärung</a
              >
              gelesen und akzeptiere diese.
            </label>
          </div>

          <button
            :disabled="!meta.valid"
            type="button"
            class="inline-block px-7 py-4 mt-6 font-medium text-md leading-tight uppercase rounded-full shadow-md focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out"
            :class="
              meta.valid
                ? 'bg-red-600 text-white hover:bg-red-700 focus:bg-red-700 active:bg-red-800 hover:shadow-lg'
                : 'bg-gray-200 text-gray-400'
            "
            @click="requestGutschein"
            @submit.prevent="requestGutschein"
          >
            Gutschein abfragen
          </button>

          <div
            v-if="apiResponse && apiResponse.gutschein !== 'Error'"
            class="m-4 form-floating"
          >
            <div
              class="text-center text-xl block w-full px-3 py-8 text-base font-normal text-gray-700"
            >
              Dein Gutschein Code lautet:
            </div>
            <span
              class="text-center text-xl block w-full px-3 text-base font-bold text-gray-700"
              >{{ apiResponse.gutschein }}</span
            >
            <div
              v-if="apiResponse && apiResponse.status == 3"
              class="text-center text-xl block w-full px-3 py-8 text-base font-normal text-blue-700"
            >
              {{ apiResponse.error }}
            </div>
          </div>
          <div v-else-if="apiResponse && apiResponse.error">
            <div
              class="text-center text-xl block w-full px-3 py-8 text-base font-normal text-red-700"
            >
              {{ apiResponse.error }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </Form>
</template>

<script>
import axios from "axios";
import NumberInput from "@/components/NumberInput";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name: "Formular",
  components: { Form, Field, ErrorMessage, NumberInput },
  props: {},
  data: () => ({
    mnr: "",
    pin: "",
    email: "",
    agbs: false,

    vnrMaxLength: 4,
    mnrMaxLength: 5,

    mnrInputMax: 9,
    pinMaxLength: 4,

    schema: yup.object({
      mnr: yup
        .string()
        .required("Die Mitgliedsnr. muss angegeben werden")
        .max(9, "Die Mitgliedsnr. darf max. 9 Zahlen lang sein"),

      pin: yup
        .string()
        .required("Ihr PIN muss angegeben werden")
        .max(4, "Der PIN muss 4 Zahlen lang sein"),
      email: yup
        .string()
        .email("Ungültige E-Mail Adresse")
        .required("Die E-Mail Adresse wird benötigt"),
      agbs: yup
        .boolean()
        .required("Die Datenschutzbestimmungen müssen akzeptiert werden"),
    }),

    pinRules: yup.string().required().min(4).max(4),
    emailRules: yup.string().email().required(),

    apiResponse: null,
  }),
  computed: {
    apiURL() {
      return process.env.VUE_APP_API_URL;
    },
  },

  methods: {
    checkFormat() {
      const vnr = this.mnr.substring(0, 4);
      const mnr = this.padLeadingZeros(
        this.mnr.substring(4),
        this.mnrMaxLength
      );

      this.mnr = `${vnr}${mnr}`;
    },

    async requestGutschein() {
      const vnr = this.mnr.substring(0, 4);
      const mnr = this.mnr.substring(4);

      try {
        const args = [
          `-A${this.padLeadingZeros(vnr, this.vnrMaxLength)}`,
          `-A${this.padLeadingZeros(mnr, this.mnrMaxLength)}`,
          `-A${this.padLeadingZeros(this.pin, this.pinMaxLength)}`,
          `-A${this.email}`,
        ];
        const url = `${this.apiURL}&arguments=${args.join(",")}`;
        let response = await axios.get(url);
        this.apiResponse = response.data;

        this.apiResponse.status = response.data.status || null;
        this.apiResponse.error = response.data.error || "";

        this.apiResponse.gutschein = response.data.gutschein || "Error";
      } catch (err) {
        console.log(err);
      }
    },

    padLeadingZeros(num, size) {
      var s = num + "";
      while (s.length < size) s = "0" + s;
      return s;
    },
  },
};
</script>

<style scoped>
.bg-oesv-red {
  background-color: #e50009;
}

.agb-link {
  text-decoration: underline;
}

.agb-link:hover {
  color: #e50009;
}
</style>
